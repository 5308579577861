import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/karyawan',
        component: () => import('@/layout/EmployeeLayout.vue'),
        redirect: { name: 'AllEmployeeView' },
        children: [
            {
                path: 'semua',
                name: 'AllEmployeeView',
                component: () => import('@/views/AllEmployeeView.vue'),
            },
            {
                path: 'buat-karyawan',
                name: 'CreateEmployeeView',
                component: () => import('@/views/CreateEmployeeView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
